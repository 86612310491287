import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import * as React from 'react'
import 'react-photoswipe/lib/photoswipe.css'

import Slider from 'react-slick'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import './gallery.scss'

type Props = {
  items: Array<{ title: string, smallSrc: IGatsbyImageData, largeSrc: IGatsbyImageData }>,
  className?: string,
}
type State = {
  showLeft: boolean;
  showRight: boolean;
  index: number;
  open: boolean;
}
const arrow = (
  <svg width="68px" height="68px" viewBox="744 519 68 68" version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink">
    <circle id="Oval" stroke="#aaa" fill="#222" fillRule="evenodd" cx="778" cy="553" r="33" />
    <polygon id="Line" stroke="none" fill="#FFFFFF" fillRule="evenodd"
             points="775.030592 543.444805 785.53122 553.945433 786.945433 552.53122 776.444805 542.030592" />
    <polygon id="Line-Copy" stroke="none" fill="#FFFFFF" fillRule="evenodd"
             points="776.444805 562.969408 786.945433 552.46878 785.53122 551.054567 775.030592 561.555195" />
  </svg>)

export default class Gallery extends React.Component<Props, State> { // #LzAsl#

  slider: Slider | undefined

  constructor(props: Props) {
    super(props)
    this.state = {
      showLeft: false,
      showRight: props.items.length > 1,
      index: 0,
      open: false,
    }
  }

  next = () => {
    this.slider!.slickNext()
  }

  previous = () => {
    this.slider!.slickPrev()
  }

  afterChange = (idx: number) => {
    this.setState({
      showLeft: idx > 0,
      showRight: idx < this.props.items.length - 1,
      index: idx,
    })
  }

  handleClick = (idx: number) => {
    if (idx !== this.state.index) return
    this.setState({
      open: true,
    })
  }

  handlePhotoSwipeClick = () => {
    this.setState({
      open: false,
    })
  }

  onClose = () => {
    this.setState({ open: false })
  }

  getThumbBoundsFn = (index: number) => {
    // find thumbnail element
    const thumbnail = document.querySelectorAll('.slick-slide')[index]
    // get window scroll Y
    const pageYScroll = window.pageYOffset || document.documentElement.scrollTop
    // optionally get horizontal scroll
    // get position of element relative to viewport
    const rect = thumbnail.getBoundingClientRect()
    return { x: rect.left, y: rect.top + pageYScroll, w: rect.width }
  }

  render() {
    // http://photoswipe.com/documentation/options.html
    const options = {
      sbgOpacity: 0.9,
      //   modal: false
      shareEl: false,
      showHideOpacity: true,
      getThumbBoundsFn: false, // http://photoswipe.com/documentation/faq.html
    }

    // const getThumbnailContent = item => {(
    //   <div className="col-xs-4">
    //     <div className="thumbnail">
    //       <img src={item.thumbnail} />
    //       <div
    //         className="gallery-caption"
    //         dangerouslySetInnerHTML={{ __html: item.title }}
    //       />
    //       {" "}
    //     </div>
    //   </div>)
    // };

    const padding = 50
    const opacity = 0.50
    // const options = {}
    // https://github.com/minhtranite/react-photoswipe
    return (<div className={this.props.className}>
        <Slider
          ref={(ref) => this.slider = ref!}
          afterChange={this.afterChange}
          arrows={false}
          infinite={false}
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          className="center"
          centerPadding="100px"
          // adaptiveHeight={true}
          centerMode
          dots={false}
          draggable

        >
          {this.props.items.map((item, idx) => <div
            style={{ overflow: 'hidden', paddingLeft: padding, paddingRight: padding }}
            onClick={this.handleClick.bind(this, idx)} // eslint-disable-line
          >
            {/*<img*/}
            {/*  key={idx}*/}
            {/*  src={item.smallSrc}*/}
            {/*  style={{*/}
            {/*    width: '100%',*/}
            {/*    filter: 'drop-shadow(0px 2px 8px #aaa)'*/}
            {/*  }}*/}
            {/*/>*/}
            <GatsbyImage
              key={idx}
              image={item.smallSrc}
              imgStyle={{ objectPosition: 'top' }}
              style={{
                // width: '100%',
                filter: 'drop-shadow(0px 2px 8px #aaa)'
              }}
              alt=""
            />
            <div
              className="gallery-caption"
              dangerouslySetInnerHTML={{ __html: item.title }}
            />
          </div>)}
        </Slider>
        <button className="button-left" onClick={this.previous} style={{
          opacity: this.state.showLeft ? opacity : 0,
          cursor: this.state.showLeft ? 'pointer' : 'default'
        }}>{arrow}</button>
        <button className="button-right" onClick={this.next} style={{
          opacity: this.state.showRight ? opacity : 0,
          cursor: this.state.showRight ? 'pointer' : 'default'
        }}>{arrow}</button>
        <Popup
          open={this.state.open} position="right center" onClose={() => this.setState({ open: false })}
          contentStyle={{ width: '95%', background: 'none', border: 'none' }}
        >
          <div>
            <GatsbyImage
              image={this.props.items[this.state.index].largeSrc}
              // imgStyle={{ objectPosition: 'top', width: '100%' }}
              style={{
                // width: '100%',
                filter: 'drop-shadow(0px 2px 8px #555)'
              }}
              alt=""
            />
          </div>
        </Popup>
      </div>
    )
  }
}
