import classNames from 'classnames'
import { Footer } from 'components/Footer'
import Gallery from 'components/Gallery/Gallery'
import { Header } from 'components/Header'
import { Wistia, wistia_popover_button, wistia_thumbnail } from 'components/wistia'
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { HomePageQuery } from 'types/graphql'

// Our section
const S = ({ gray, children, className = '' }: { children: React.ReactNode, gray?: boolean, className?: string }) =>
  <section
    className={classNames('my-section', className, { 'linear-gray': gray })}>
    <div className="container-w">{children}</div>
  </section>

const IndexPage: React.FC<PageProps<HomePageQuery>> = ({ data }) => {

  return (
    <div>
      <Header fixed={false} />
      <main>
        <title>CodeStory</title>
        <S className="pt-12 sm:pb-5">
          <h2 className="text-center mb-2 text-3xl text-red-500 font-medium">A workspace for documenting software projects</h2>
          <p className="text-2xl px-3 text-gray-500">
            Describe how anything works and show the relevant code on the same page, using Live Views from your codebase, and much more!
          </p>
        </S>
        <div className="hidden sm:block w-full" style={{ height: 400, overflow: 'hidden' }}>
          <Wistia id="xbjbstdkue" style={{margin: 'auto', maxWidth: 1440, minWidth: 800}} />
        </div>
        <S gray className="sm:py-8 hidden sm:block">
          <div className="grid grid-cols-3 gap-4 text-gray-500">
            <div className="text-2xl font-bold -mt-1 text-right pr-1">Addressing the long-standing issues of code
              documentation
            </div>
            <div className="text-sm">
              <b>Code comments: no big picture.</b><br />While they are OK to explain the purpose of a module, function
              or
              block of code, code comments fall short of providing the big picture, because any feature or mechanism
              you’ll want to describe most likely involves multiple chunks of code spread across multiple files.
              <br /><br />
              <b>Hard to maintain.</b><br />A minor refactoring and the existing documentation gets irrelevant, without
              anyone even noticing.
            </div>
            <div className="text-sm">
              <b>Wikis: cumbersome.</b><br />Relying on a separate document (such as a wiki) would be the only way to
              provide the overview and enjoy a rich text documentation. However, making the connection between the notes
              and the code turns out to be a pain.
              <br /><br />
              <b>No immediate benefits.</b><br />Writing a documentation and keeping it up to date takes some time and
              effort: it has a substantive, immediate cost and requires great discipline. The developer doesn't
              perceive that his documentation task as useful for himself.
            </div>
          </div>
        </S>
        <S className="sm:py-12">
          <h2>
            <span className="eyebrow">Live Code Views</span>
            <span>Connect your notes to your code, and vice versa.</span>
          </h2>
          <p className="text-center text-2xl text-gray-500">Embed live views of your code right in the middle of your
            notes. Jump into
            your favorite editor, or the other way round. Just need to do a small edit ? Click into the view and
            transition like magic into a full-page editor, without leaving your note.</p>
          <div>
            <div className="grid sm:grid-cols-3 gap-4">
              <div className="col-span-2 px-2">
                <div className="my-shadow rounded-2xl overflow-hidden"
                     dangerouslySetInnerHTML={wistia_thumbnail('hrzq82losf')} />
              </div>
              <div className="text-base font-thin">
                <p className="mt-0 pl-2">Any feature of your application most likely involves multiple portions of code,
                  spread
                  across multiple files. Code comments don't let you draw the big picture, and using a separate
                  documentation is cumbersome and nearly impossible to maintain.</p>
                <p className="pl-2">With Live Code Views, you are now able to document your project in a rich-text
                  editor
                  and insert live,
                  editable views of your codebase right into your notes.</p>
                <p className="text-center">{wistia_popover_button('hrzq82losf', 'Watch Live Code Views in action')}</p>

              </div>
            </div>
          </div>
        </S>
        <S gray className="sm:py-12">
          <h2>
            <span className="eyebrow">Sequence Diagrams</span>
            <span>A bird's eye view of your code...</span>
          </h2>
          <p className="text-center text-2xl text-gray-500">CodeStory pushes the well-known sequence diagrams to the
            next level with rich content and Live Code Views
            popovers.</p>
          <div className="video" style={{ lineHeight: 0 }}>
            <video className="rounded-2xl overflow-clip my-shadow" muted width="100%" autoPlay={true} loop>
              <source
                src="https://s3.fr-par.scw.cloud/codestory-web/media/Diagram_2.mp4"
                type="video/mp4" />
              Sorry, your browser doesn't support embedded videos.
            </video>
          </div>
        </S>
        <S>
          <h2>
            <span className="eyebrow">Real world examples</span>
            <span>Your code is sophisticated, not complicated!</span>
          </h2>
          <p className="text-center text-2xl text-gray-500">You'll be amazed to see that your documentation doesn't need
            to be verbose:
            using Live Code Views, code simply untangles itself..</p>
        </S>
        <div className="overflow-x-hidden">
          <Gallery
            className="component-gallery sm:mx-auto max-w-6xl hidden sm:block"
            items={data.realWorldExamples.nodes.map(node => ({
              title: node.text!,
              // smallSrc: node.image!.small!.resize.src,
              smallSrc: node.image!.small!.gatsbyImageData,
              largeSrc: node.image!.large!.gatsbyImageData,
            }))} />
        </div>
        <S className="sm:hidden flex flex-col">
          {data.realWorldExamples.nodes.map((node, idx) => (
            <>
              <div
                className="gallery-caption"
                dangerouslySetInnerHTML={{ __html: node.text! }}
              />
              <GatsbyImage
                key={idx}
                image={node.image!.small!.gatsbyImageData}
                // fluid={node.image!.small!.fluid as FluidObject}
                imgStyle={{ objectPosition: 'top' }}
                style={{ filter: 'drop-shadow(0px 2px 8px #aaa)' }}
                alt=""
              />
              {idx < data.realWorldExamples.nodes.length - 1 && <hr />}
            </>
          ))}
        </S>
      </main>
      <Footer />
    </div>
  )
}

// https://www.npmjs.com/package/react-device-detect
export default IndexPage

export const query = graphql`
    query HomePage {
        realWorldExamples: allExamplesYaml {
            nodes {
                text
                image {
                    small: childImageSharp {
                        gatsbyImageData(width: 800)
                    }
                    large: childImageSharp {
                        gatsbyImageData(width: 1200)
                    }
                }
            }
        }
    }
`
